import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import Header from 'components/app/project/header/header-basic'
import FormHeader from 'components/shared/header-form'
import useProspect from 'context/prospect/use'
import fireEvent from 'context/tracking/events'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint } from 'styles/app/system'

const Locations = (_: RouteComponentProps) => {
  const { designer, hasProject, prospect, stopPolling, refetch } = useProspect()

  useEffect(() => {
    if (hasProject && designer) {
      stopPolling()
      refetch()
      navigate(`/welcome/${prospect?.id}/thank-you`)
    }
  }, [prospect, designer])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <AppLayout
      header={
        prospect?.id ? (
          <Header onBack={() => navigate?.(`/welcome/${prospect.id}`)} />
        ) : (
          <FormHeader />
        )
      }
    >
      <StyledGrid stackable>
        {/* Add notifications here */}
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h2>Pick a Studio to Visit</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row></Grid.Row>
        <Grid.Row stretched styled={{ margin: '0 5px' }}>
          <Grid.Column largeScreen={5} computer={5} tablet={8}>
            <SelectImageCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/new-york-studio.webp`}
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`new-york/`)
              }}
              text={'New York'}
              subtitle={
                <>
                  <i>FORM Studio</i>
                  <br />
                  200 Lexington Ave STE 429
                  <br />
                  New York, NY 10016
                </>
              }
            />
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={8}>
            <SelectImageCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/denver-studio.webp`}
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`denver/`)
              }}
              text={'Denver'}
              subtitle={
                <>
                  <i>FORM Studio</i>
                  <br />
                  2624 W 32nd
                  <br />
                  Denver, CO 80211
                </>
              }
            />
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={8}>
            <SelectImageCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/san-francisco-studio.webp`}
              onClick={() => {
                fireEvent({
                  type: 'schedule_started',
                })
                navigate(`san-francisco/`)
              }}
              text={'San Francisco'}
              subtitle={
                <>
                  <i>Form Partner | Function Renovations</i>
                  <br />
                  101 Henry Adams St, STE 376
                  <br />
                  San Francisco, CA 94103
                </>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </StyledGrid>
    </AppLayout>
  )
}

export default Locations

const StyledGrid = styled(Grid)`
  &&&&&&&& {
    @media ${Breakpoint.onlyMobile} {
      margin: 10px 0;
    }
    .row {
      margin-bottom: 20px;
      row-gap: 16px;
      div.column {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }
  }
`
